import React from "react"
import Helmet from "react-helmet"
import {} from "react-head"
import Footer from "../Footer"
import NavigationBar from "../NavigationBar"
import favicon from "../../images/favicon.svg"
import "./Layout.scss"

const pageTitleBase = "Savvy Investments and Planning"

export default function Layout(props) {
    const { activeTab, title = "", children } = props
    const pageTitle = activeTab
        ? `${pageTitleBase} - ${
              activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
          }`
        : title
        ? `${pageTitleBase} - ${title}`
        : pageTitleBase
    return (
        <div className={`${activeTab} layout`}>
            <Helmet>
                <meta
                    name="viewport"
                    charSet="utf-8"
                    content="width=device-width, initial-scale=1.0"
                ></meta>
                <title>{pageTitle}</title>
                <link rel="icon" href={favicon} />
                <script>
                    {`{
                        var clicky_site_ids = clicky_site_ids || [];
                        clicky_site_ids.push(101308616);
                    }`}
                </script>
                <script async src="//static.getclicky.com/js"></script>
            </Helmet>
            <NavigationBar active={activeTab} />
            <div className="layout__content">{children}</div>
            <Footer fixToBottom={props.fixToBottom} />
        </div>
    )
}
