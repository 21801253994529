import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import { Link } from "gatsby"
import logo from "../../images/logos/savvy-logo_full.svg"
import "bootstrap/dist/css/bootstrap.min.css"
import "./NavigationBar.scss"

export default function NavigationBar(props) {
    var tabs = [
        { name: "home", ref: "/" },
        { name: "planning", ref: "/planning" },
        { name: "investments", ref: "/investments" },
        { name: "about", ref: "/about" },
        //{ name: "consultation", ref: "/consultation" },
    ]
    return (
        <Navbar
            className="navigation-bar"
            expand="md"
            variant="light"
            sticky="top"
        >
            <Navbar.Brand>
                <img className="navigation-bar__logo" src={logo} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
                <Nav className="navigation-bar__nav">
                    {tabs.map(tab => (
                        <Link
                            className={`navigation-bar__tab-link ${
                                props.active === tab.name
                                    ? "navigation-bar__tab-link--active"
                                    : ""
                            }`}
                            key={tab.name}
                            to={tab.ref}
                        >
                            {tab.name}
                        </Link>
                    ))}
                </Nav>
                <DropdownButton
                    title="for current clients"
                    variant="success"
                    className="navigation-bar__current-clients-dropdown"
                    id="dropdown-menu-align-right"
                >
                    <Dropdown.Item
                        target="_blank"
                        href="https://www.rightcapital.com/login?cobrand=tEvz3C8pO69YjDmsLLV-Aw&type=client"
                    >
                        planning portal
                    </Dropdown.Item>
                    <Dropdown.Item
                        target="_blank"
                        href="https://www.advisorclient.com/login"
                    >
                        investments access
                    </Dropdown.Item>
                </DropdownButton>
            </Navbar.Collapse>
        </Navbar>
    )
}
